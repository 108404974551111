// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600&family=Roboto:wght@400&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  background-color: black;
  color: #ffffff;
  font-size: 10px;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  /* Position the tooltip */
  position: absolute;
  z-index: 4;
  bottom: 30px;
  left: -6px;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./src/components/reusable/Tooltip/tooltip.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,qBAAA;AAAF;;AAGA;EACE,kBAAA;EAEA,uBCFM;EDGN,cCPc;EDQd,eCSiB;EDRjB,kBAAA;EACA,kBAAA;EACA,YAAA;EAEA,yBAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,UAAA;AAFF;;AAKA;EACE,mBAAA;AAFF","sourcesContent":["@import \"styles/variables.scss\";\n\n.tooltip {\n  position: relative;\n  display: inline-block;\n}\n\n.tooltip .tooltip-text {\n  visibility: hidden;\n\n  background-color: $black;\n  color: $primary-white;\n  font-size: $font-extra-small;\n  text-align: center;\n  border-radius: 6px;\n  padding: 5px;\n\n  /* Position the tooltip */\n  position: absolute;\n  z-index: 4;\n  bottom: 30px;\n  left: -6px;\n}\n\n.tooltip:hover .tooltip-text {\n  visibility: visible;\n}\n","@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600&family=Roboto:wght@400&display=swap\");\n\n// COLORS\n\n$primary-white: #ffffff;\n$secondary-white: #dcdcdc;\n$primary-grey: #595959;\n\n$black: black;\n$blue: #070751;\n$light-blue: #4396ca;\n$dark-blue: #06063a;\n$light-grey: #eaeaea7b;\n$gray: #f4f6f7;\n$mid-gray: #c5c5c5cc;\n$dark-gray: #4f4f4f;\n$red: #ef233c;\n$alert-red: #d90429;\n\n//FONT SIZES\n\n$font-extra-small: 10px;\n$font-small: 12px;\n$font-small-medium: 14px;\n$font-default: 16px;\n\n$roboto: \"Roboto\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
