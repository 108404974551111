import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FavoriteOffer } from "components/models/offer";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import { MdDelete, MdOpenInNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  GridColDef,
  plPL,
  enUS,
  GridCellParams,
} from "@mui/x-data-grid";

interface FavoritesTableProps {
  favoriteOffers: FavoriteOffer[];
  onDelete: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: FavoriteOffer["id"]
  ) => void;
}

const FavoritesTable = ({ favoriteOffers, onDelete }: FavoritesTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const localLang = localStorage.getItem("lang");

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    offer: FavoriteOffer
  ) => {
    e.preventDefault();
    navigate(`/offer/${offer.id}`);
  };

  const columns: GridColDef[] = [
    {
      field: "title",
      minWidth: 250,
      flex: 2,
      headerName: t("profile:title"),
    },
    {
      field: "type",
      minWidth: 150,
      flex: 1,
      headerName: t("profile:type"),
      renderCell: (params: GridCellParams) => {
        const offer = params.row;

        return <>{offer.type && offer.type.name ? offer.type.name : "-"}</>;
      },
    },
    {
      field: "city",
      minWidth: 10,
      flex: 1,
      headerName: t("profile:favorite-city"),
      renderCell: (params: GridCellParams) => {
        const offer = params.row;

        return <>{offer.city && offer.city.name ? offer.city.name : "-"}</>;
      },
    },
    {
      field: "district",
      minWidth: 150,
      flex: 1,
      headerName: t("profile:favorite-district"),
      renderCell: (params: GridCellParams) => {
        const offer = params.row;

        return (
          <>
            {offer.district && offer.district.name ? offer.district.name : "-"}
          </>
        );
      },
    },
    {
      field: "size",
      minWidth: 100,
      flex: 1,
      headerName: t("profile:favorite-size"),
      renderCell: (params: GridCellParams) => {
        const offer = params.row;

        return (
          <>
            {offer.size ? offer.size : "-"} m<sup>2</sup>
          </>
        );
      },
    },
    {
      field: "actions",
      minWidth: 150,
      flex: 1,
      headerName: t("profile:actions"),
      renderCell: (offer: any) => {
        return (
          <>
            <TooltipComponent
              WrappedComponent={
                <IconButton onClick={(e) => handleClick(e, offer)}>
                  <MdOpenInNew size={24} />
                </IconButton>
              }
              message={t("profile:favorite-go-to-offer")}
            />
            <TooltipComponent
              WrappedComponent={
                <IconButton onClick={(e) => onDelete(e, offer.id)}>
                  <MdDelete size={24} />
                </IconButton>
              }
              message={t("profile:delete")}
            />
          </>
        );
      },
    },
  ];
  columns.forEach((col) => (col.headerClassName = "MuiTableCell-head"));
  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item sx={{ width: "100%" }}>
          <DataGrid
            autoHeight
            rows={favoriteOffers}
            columns={columns}
            disableRowSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: "created_at", sort: "desc" }],
              },
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            localeText={
              localLang === "en"
                ? enUS.components.MuiDataGrid.defaultProps.localeText
                : plPL.components.MuiDataGrid.defaultProps.localeText
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FavoritesTable;
