// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0px;
  padding: 0px !important;
  overflow: visible !important;
}

* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.text-bold {
  font-weight: 700;
}

p {
  line-height: 1.7;
}

a {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,uBAAA;EACA,4BAAA;AACF;;AACA;EACE,gDAAA;AAEF;;AAAA;EACE,gBAAA;AAGF;;AADA;EACE,gBAAA;AAIF;;AAFA;EACE,qBAAA;AAKF","sourcesContent":["body {\n  margin: 0px;\n  padding: 0px !important;\n  overflow: visible !important;\n}\n* {\n  font-family: Verdana, Geneva, Tahoma, sans-serif;\n}\n.text-bold {\n  font-weight: 700;\n}\np {\n  line-height: 1.7;\n}\na {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
