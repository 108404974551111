// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-notifications {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
  gap: 5px;
  display: grid;
}

.error-notifications > * {
  bottom: unset !important;
  left: unset !important;
  right: 0px !important;
  position: relative !important;
  display: inline !important;
}`, "",{"version":3,"sources":["webpack://./src/components/reusable/Notification/notification.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,QAAA;EACA,aAAA;AACF;;AACA;EACE,wBAAA;EACA,sBAAA;EACA,qBAAA;EACA,6BAAA;EACA,0BAAA;AAEF","sourcesContent":[".error-notifications {\r\n  position: fixed;\r\n  top: 20px;\r\n  right: 20px;\r\n  z-index: 9999;\r\n  gap: 5px;\r\n  display: grid;\r\n}\r\n.error-notifications > * {\r\n  bottom: unset !important;\r\n  left: unset !important;\r\n  right: 0px !important;\r\n  position: relative !important;\r\n  display: inline !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
