// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/slick-carousel/slick/slick.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/slick-carousel/slick/slick-theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
