// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../index.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600&family=Roboto:wght@400&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: auto;
  animation: spin 1s linear infinite;
}

button > .loader {
  width: 17px;
  height: 17px;
  margin: -5px auto;
}`, "",{"version":3,"sources":["webpack://./src/components/views/utils/loader.scss"],"names":[],"mappings":"AAGA;EACE;IACE,uBAAA;EAAF;EAEA;IACE,yBAAA;EAAF;AACF;AAEA;EACE,yBAAA;EACA,iCAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,kCAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAAF","sourcesContent":["@import url(\"../../../index.scss\");\n@import \"styles/variables.scss\";\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.loader {\n  border: 2px solid #f3f3f3;\n  border-top: 2px solid transparent;\n  border-radius: 50%;\n  width: 20px;\n  height: 20px;\n  margin: auto;\n  animation: spin 1s linear infinite;\n}\n\nbutton > .loader {\n  width: 17px;\n  height: 17px;\n  margin: -5px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
