// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../nodevenv/domains/realn.hostline.net.pl/frontend-main/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600&family=Roboto:wght@400&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0px;
  padding: 0px !important;
  overflow: visible !important;
}

* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.text-bold {
  font-weight: 700;
}

p {
  line-height: 1.7;
}

a {
  text-decoration: none;
}

.premium-header {
  height: 400px;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  padding: 40px;
}
.premium-header h1,
.premium-header h3 {
  position: relative;
  top: 50%;
}
.premium-header h3 {
  font-size: 20px;
}

.premium-search__box {
  display: flex;
  flex-direction: row;
  padding: 20px 5%;
  justify-content: space-around;
  align-items: center;
}
.premium-search__box .premium-search__text {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 5%;
}
.premium-search__box .premium-search__input {
  display: flex;
  width: 40%;
  height: 40px;
}
.premium-search__box .premium-search__input input {
  width: 80%;
  height: 36px;
}
.premium-search__box .premium-search__input button {
  border-radius: 0px 6px 6px 0px;
  margin: 0px;
  padding: 12px;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/index.scss","webpack://./src/components/views/premium/premium.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,uBAAA;EACA,4BAAA;ACEF;;ADAA;EACE,gDAAA;ACGF;;ADDA;EACE,gBAAA;ACIF;;ADFA;EACE,gBAAA;ACKF;;ADHA;EACE,qBAAA;ACMF;;AAlBA;EACE,aAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AAqBF;AApBE;;EAEE,kBAAA;EACA,QAAA;AAsBJ;AApBE;EACE,eAAA;AAsBJ;;AAlBA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,6BAAA;EACA,mBAAA;AAqBF;AApBE;EACE,aAAA;EACA,sBAAA;EACA,UAAA;EACA,gBAAA;AAsBJ;AApBE;EACE,aAAA;EACA,UAAA;EACA,YAAA;AAsBJ;AArBI;EACE,UAAA;EACA,YAAA;AAuBN;AArBI;EACE,8BAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;AAuBN","sourcesContent":["body {\n  margin: 0px;\n  padding: 0px !important;\n  overflow: visible !important;\n}\n* {\n  font-family: Verdana, Geneva, Tahoma, sans-serif;\n}\n.text-bold {\n  font-weight: 700;\n}\np {\n  line-height: 1.7;\n}\na {\n  text-decoration: none;\n}\n","@import \"../../../index.scss\";\n@import \"styles/variables.scss\";\n\n.premium-header {\n  height: 400px;\n  margin-top: 70px;\n  display: flex;\n  flex-direction: column;\n  padding: 40px;\n  h1,\n  h3 {\n    position: relative;\n    top: 50%;\n  }\n  h3 {\n    font-size: 20px;\n  }\n}\n\n.premium-search__box {\n  display: flex;\n  flex-direction: row;\n  padding: 20px 5%;\n  justify-content: space-around;\n  align-items: center;\n  .premium-search__text {\n    display: flex;\n    flex-direction: column;\n    width: 50%;\n    margin-right: 5%;\n  }\n  .premium-search__input {\n    display: flex;\n    width: 40%;\n    height: 40px;\n    input {\n      width: 80%;\n      height: 36px;\n    }\n    button {\n      border-radius: 0px 6px 6px 0px;\n      margin: 0px;\n      padding: 12px;\n      height: 40px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
