import {
  IconButton,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { SearchCriteria } from "components/models/offer";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import { useAppSelector } from "components/store/configureStore";
import {
  MdArrowCircleDown,
  MdArrowCircleUp,
  MdDelete,
  MdOpenInNew,
} from "react-icons/md";
import getAxiosParams from "../utils/getParams";
import { useNavigate } from "react-router-dom";
import { VALUE_SUFFIX } from "utils/constants";
import { useTranslation } from "react-i18next";

const MAX_LOCATIONS_VISIBLE = 3;

interface SearchCriteriasTableProps {
  favoriteSearchCriterias: SearchCriteria[];
  onDelete: (id: SearchCriteria["id"]) => void;
}

const SearchCriteriasTable = ({
  favoriteSearchCriterias,
  onDelete,
}: SearchCriteriasTableProps) => {
  const { t } = useTranslation();
  const { City, District } = useAppSelector((state) => state.utility.table);
  const navigate = useNavigate();

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    criteria: SearchCriteria
  ) => {
    e.preventDefault();
    navigate(`/search/${getAxiosParams(criteria)}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} className="search-criterias-table">
        <TableHead>
          <TableRow>
            <TableCell>{t("profile:search-criteria-locations")}</TableCell>
            <TableCell align="right" className="md-cell">
              {`${t("profile:price")} (${VALUE_SUFFIX.price})`}
            </TableCell>
            <TableCell align="right" className="md-cell">
              {t("profile:search-criteria-sorted-by")}
            </TableCell>
            <TableCell align="right" className="md-cell">
              {t("profile:search-criteria-sort-order")}
            </TableCell>
            <TableCell align="right">
              {t("profile:search-criteria-actions")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {favoriteSearchCriterias.map((criteria) => (
            <TableRow
              key={criteria.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <List>
                  {criteria.location
                    ?.slice(0, MAX_LOCATIONS_VISIBLE)
                    .map((location) => (
                      <ListItem disableGutters key={location}>
                        {
                          City.find(
                            (city) => city.id === location.split(",")[0]
                          )?.name
                        }{" "}
                        {
                          District.find(
                            (district) => district.id === location.split(",")[1]
                          )?.name
                        }
                      </ListItem>
                    ))}
                  {criteria.location &&
                    criteria.location?.length > MAX_LOCATIONS_VISIBLE && (
                      <TooltipComponent
                        WrappedComponent={
                          <ListItem disableGutters className="more-locations">
                            {t("profile:search-criteria-more-locations", {
                              count:
                                criteria.location.length -
                                MAX_LOCATIONS_VISIBLE,
                            })}
                          </ListItem>
                        }
                        message={
                          <List>
                            {criteria.location
                              .slice(MAX_LOCATIONS_VISIBLE)
                              .map((location) => (
                                <ListItem disableGutters key={location}>
                                  {
                                    City.find(
                                      (city) =>
                                        city.id === location.split(",")[0]
                                    )?.name
                                  }{" "}
                                  {
                                    District.find(
                                      (district) =>
                                        district.id === location.split(",")[1]
                                    )?.name
                                  }
                                </ListItem>
                              ))}
                          </List>
                        }
                      />
                    )}
                </List>
              </TableCell>
              <TableCell className="md-cell" align="right">
                {criteria.min_price} - {criteria.max_price}
              </TableCell>
              <TableCell align="right" className="md-cell">
                {criteria.order_by}
              </TableCell>
              <TableCell align="right" className="md-cell sort-order">
                {criteria.order ? (
                  <TooltipComponent
                    WrappedComponent={
                      criteria.order === "asc" ? (
                        <MdArrowCircleUp />
                      ) : (
                        <MdArrowCircleDown />
                      )
                    }
                    message={t(
                      `profile:search-criteria-sort-order-${criteria.order}`
                    )}
                  />
                ) : (
                  "-"
                )}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  "& > div:not(:last-child)": { mr: 1 },
                }}
              >
                <TooltipComponent
                  WrappedComponent={
                    <IconButton
                      aria-label="edit"
                      onClick={(e) => handleClick(e, criteria)}
                    >
                      <MdOpenInNew size={24} />
                    </IconButton>
                  }
                  message={t("profile:search-criteria-show-results")}
                />
                <TooltipComponent
                  WrappedComponent={
                    <IconButton
                      aria-label="edit"
                      onClick={() => onDelete(criteria.id)}
                    >
                      <MdDelete size={24} />
                    </IconButton>
                  }
                  message={t("profile:delete")}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SearchCriteriasTable;
