import { Container, Grid, IconButton } from "@mui/material";
import { DataGrid, GridColDef, plPL, enUS } from "@mui/x-data-grid";
import { ResponseOffer } from "components/models/offer";
import TooltipComponent from "components/reusable/Tooltip/Tooltip";
import { MdContentCopy, MdDelete, MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import notFoundImage from "images/home-placeholder.webp";
import { formatPrice } from "utils/helpers";
import { useAppSelector } from "components/store/configureStore";
import { TableElement } from "components/models/table";
import dayjs from "dayjs";
import { DATE_FORMAT } from "utils/constants";

interface Props {
  userOffers: ResponseOffer[];
  onDelete: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: ResponseOffer["id"]
  ) => void;
  onDuplicate: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: ResponseOffer["id"]
  ) => void;
}

const OfferTable = ({ userOffers, onDelete, onDuplicate }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const table = useAppSelector((state) => state.utility.table);
  const localLang = localStorage.getItem("lang");

  const offers = userOffers
    .slice()
    .sort((a, b) => (a.created_at > b.created_at ? -1 : 1));

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    offer: ResponseOffer
  ) => {
    e.stopPropagation();
    navigate(`/catalog/${offer.id}`);
  };

  const columns: GridColDef[] = [
    {
      field: "featured_image",
      minWidth: 120,
      flex: 1,
      headerName: "",
      renderCell: (offer: any) => (
        <img
          src={offer.value ? offer.value.variants.thumbnail : notFoundImage}
          alt=""
          className="user-offer-img"
        />
      ),
    },
    {
      field: "title",
      minWidth: 350,
      flex: 2,
      headerName: t("profile:title"),
    },
    {
      field: "created_at",
      minWidth: 140,
      flex: 1,
      headerName: t("profile:createdAt"),
      valueFormatter: (params) => dayjs(params?.value).format(DATE_FORMAT),
    },
    {
      field: "type",
      minWidth: 80,
      flex: 1,
      headerName: t("profile:type"),
      renderCell: (offer: any) =>
        table.OfferType.find(
          (element: TableElement) => element.id === offer.value?.id
        )?.name ?? "-",
    },
    {
      field: "price",
      minWidth: 200,
      flex: 1,
      headerName: t("profile:price"),
    },
    {
      field: "action",
      minWidth: 150,
      flex: 1,
      headerName: t("profile:actions"),
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (offer: any) => {
        return (
          <div>
            <TooltipComponent
              WrappedComponent={
                <IconButton
                  aria-label="duplicate"
                  onClick={(e) => onDuplicate(e, offer.id)}
                >
                  <MdContentCopy size={24} />
                </IconButton>
              }
              message={t("profile:duplicate-offer")}
            />
            <TooltipComponent
              WrappedComponent={
                <IconButton
                  aria-label="edit"
                  onClick={(e) => handleClick(e, offer)}
                >
                  <MdOutlineEdit size={24} />
                </IconButton>
              }
              message={t("profile:edit-offer")}
            />
            <TooltipComponent
              WrappedComponent={
                <IconButton onClick={(e) => onDelete(e, offer.id)}>
                  <MdDelete size={24} />
                </IconButton>
              }
              message={t("profile:delete")}
            />
          </div>
        );
      },
    },
  ];

  columns.forEach((col) => (col.headerClassName = "MuiTableCell-head"));

  return (
    <Container
      sx={{
        paddingLeft: "0 15px 0 0",
        marginLeft: "-15px",
        maxWidth: "unset !important",
      }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item sx={{ width: "100%" }}>
          <DataGrid
            autoHeight
            rows={offers}
            columns={columns}
            disableRowSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: "created_at", sort: "desc" }],
              },
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            onRowClick={(params, e) => {
              e.stopPropagation();
              navigate(`/offer/${params.row.id}`);
            }}
            localeText={
              localLang === "en"
                ? enUS.components.MuiDataGrid.defaultProps.localeText
                : plPL.components.MuiDataGrid.defaultProps.localeText
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default OfferTable;
